<template>
    <div class="page1">
        <EForm :formColumns="formColumns"
               :rowSize="4"
               :optionsBtn="true"
               :actionBtn="false"
               :formData="searchForm"
               ref="form"
               @onSearch="onSearch"
               :searchFlag="true"
               :exportShow="false"
        >
        </EForm>
        <div class="mt20 fs15 flex flex-space-between">
            <div>
                收费合计：{{collectData.chargeTotal}}元，退费合计：{{collectData.refundTotal}}元
            </div>
            <div>
                <el-button type="primary" size="small" @click="exportFile">导出</el-button>
                <el-button type="primary" size="small" @click="printDiv">打印</el-button>
            </div>

        </div>
        <el-table
                :header-cell-style="{background:'#f4f4f5'}"
                tooltip-effect="dark"
                style="width: 100%;margin-bottom: 20px;margin-top: 20px;color:#000;font-size:18px;border-color:#999;"
                :data="tableData2"
                size="small"
                border
                lazy
                class="break-page"
                :span-method="objectSpanMethod"
                show-summary
                :summary-method="getSummaries"
                :header-row-style="{color:'#000',fontSize:'18px'}"


        >
            <el-table-column v-for="(item,index) in tableTitle" :prop="item.prop" :label="item.label"
                             :key="index"
                             header-align="center"
                             style="font-size: 12px"
                             align="center"


            >
                <template slot-scope="scope">
                    <div :class="{'break-page':index==4}">
                    </div>
                    <div v-if="scope.row[item.prop]==null || scope.row[item.prop]===''"> -</div>
                    <div v-else> {{scope.row[item.prop]}}</div>
                </template>
            </el-table-column>
        </el-table>
        <!--打印的dom-->
        <div class="print-dom">
            <div ref="print" class="print-box fs13">
                <div class="tc fs22 fwb mb10">
                    费用分类统计表
                </div>
                <div class="flex ">
                <span class="flex-1" v-if="searchForm.payTimeStart&&searchForm.payTimeEnd">
                    统计期间：{{searchForm.payTimeStart}}~{{searchForm.payTimeEnd}}
                </span>
                    <span class="flex-1">
                    收费人：{{chargePerson}}
                </span>
                </div>
                <el-table
                        :header-cell-style="{background:'#f4f4f5'}"
                        tooltip-effect="dark"
                        style="width: 1000px;margin-bottom: 10px;margin-top: 10px;color:#000;font-size:13px;border:2px solid #999;line-height: 13px"
                        class="print-table"
                        :class="{'break-page':count!=tableNum,'another-page':count!=1}"
                        :data="tableData2.slice((count-1)*20,count*20>=tableData2.length?tableData2.length:count*20)"
                        size="small"
                        border
                        lazy
                        v-for="count in  tableNum" :key="count"
                        :span-method="objectSpanMethod"
                        :show-summary="count==tableNum"
                        :summary-method="getSummaries"
                >
                    <el-table-column v-for="(item,index) in tableTitle" :prop="item.prop" :label="item.label"
                                     :key="index"
                                     header-align="right"
                                     :align="index==0||index==1?'left':'right'"
                                     :width="item.width"
                    >
                        <template slot-scope="scope">
                            <div v-if="scope.row[item.prop]==null || scope.row[item.prop]===''"> -</div>
                            <div v-else> {{scope.row[item.prop]}}</div>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="flex mt20">
                <span class="flex-1">
                    打印人：{{userInfo.userName}}
                </span>
                    <span class="flex-1">
                    打印时间：{{time}}
                </span>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
  import STable from '@/components/STable'
  import Http from "@/service/http";
  import EDialog from '@/components/EDialog'
  import EButton from '@/components/EButton'
  import EForm from '@/components/EForm'
  import {identity_type, status} from '@/assets/js/config'
  import vxRule from "@/assets/js/formValidate";
  import {mapGetters} from "vuex";
  import {dateFormat} from '@/assets/js/common'
  import {exportExcel} from '@/assets/js/exportExcel'

  export default {
    name: 'feeDaily',
    data() {
      return {
        tableTitle: [],
        formColumns: [
          {
            title: '开始日期',
            type: 'datePicker',
            property: 'payTimeStart',
            show: true
          },
          {
            title: '截止日期',
            type: 'datePicker',
            property: 'payTimeEnd',
            show: true
          },
          {
            title: '收费人',
            type: 'select',
            property: 'chargePersonId',
            show: true,
            options: []
          },
          {
            title: '收费项目',
            multiple: true,
            type: 'select',
            property: 'takeReturnProjectIds',
            show: true,
            options: [],
          },
        ],
        tableData: [],
        searchForm: {
          payTimeStart: null,
          payTimeEnd: null,
          chargePersonId: null,
          takeReturnProjectIds:null,

        },
        count: null,
        exportData: {},
        dialogVisible: false,
        labelPosition: 'right',
        labelWidth: '100px',
        formRules: {
          boothCode: vxRule(true, '', "blur", "摊位编号不能为空"),
          areas: vxRule(true, '', "blur", "面积不能为空"),
          areaId: vxRule(true, '', "change", "区域不能为空"),
          boothUseId: vxRule(true, '', "change", "摊位用途不能为空"),
          placeId: vxRule(true, '', "change", "位置不能为空"),
          boothTypeId: vxRule(true, '', "change", "摊位类型不能为空"),
          remark: vxRule(true, '', "blur", "说明不能为空"),
        },
        collectData: {
          chargeTotal: 0,
          refundTotal: 0
        },
        dialogForm: {
          boothInformationId: '',
          boothCode: '',
          areas: '',
          areaId: '',
          boothUseId: '',
          placeId: '',
          boothTypeId: '',
          remark: '',
        },
        options: [],
        form: {},
        areaOptions: [],
        locationOptions: [],
        boothUseOptions: [],
        boothTypeOptions: [],
        tableTitle2: [
          {
            label: '收费项目名称',
            prop: 'takeReturnProjectName'
          },
          {
            label: '年',
            prop: 'year',
          },
          {
            label: '月',
            prop: 'month',
          },
          {
            label: '费用起始日期',
            prop: 'feeBeginDate',
          },
          {
            label: '费用截止日期',
            prop: 'feeEndDate',
          },
          {
            label: '收费金额',
            prop: 'payAmount',
          },
          {
            label: '第几期',
            prop: 'periods',
          },
          {
            label: '是否到账',
            prop: 'isPayCn',
          },
          {
            label: '是否打印交费单',
            prop: 'isPrintCn',
          },
          {
            label: '收/退',
            prop: 'dealTypeCn',
          },
          {
            label: '退费金额',
            prop: 'returnAmount',
          },
          {
            label: '备注',
            prop: 'remark',
          },
        ],
        tableData2: [],
        tableNum: 1,//要渲染多少个表格
        total: {
          name: "总计",
          total: "27012190.52",
          type: "",
          value1: "771.52",
          value2: "9.00",
          value3: "27011410.00"
        },
        chargePerson: '所有',
      }
    },
    computed: {
      ...mapGetters(['userInfo']),
      time() {
        return dateFormat.formatDate(new Date(), 'yyyy-MM-dd hh:mm:ss')
      },
    },
    watch: {},
    created() {
      this.searchForm.payTimeStart = dateFormat.formatDate(new Date(), 'yyyy-MM-dd')
      this.searchForm.payTimeEnd = dateFormat.formatDate(new Date(), 'yyyy-MM-dd')
      this.getData()
      this.userLabel()
      this.getPayItem();

    },
    components: {STable, EDialog, EButton, EForm},
    beforeMount() {

    },
    methods: {
      exportDataFn(){
        let {
          payTimeStart,
          payTimeEnd,
          chargePersonId,
          takeReturnProjectIds
        } = this.searchForm;
        return  {
          payTimeStart,
          payTimeEnd,
          chargePersonId,
          takeReturnProjectIds:takeReturnProjectIds?takeReturnProjectIds.toString():''
        }
      },
      // 获取收费项目下拉
      async getPayItem() {
        let res = await Http.gettakereturnprojectLabel({ areaId: null,type:"1" });
        if (res.code == 200) {
          this.formColumns[3].options = res.data;
        }
      },
       exportFile() {
         Http.recieveAmountListExport(this.exportDataFn(), [], false, '收费日报统计')
        // exportExcel('api/cm-report/cmreport/recieveAmountListExport', this.searchForm, '收费日报统计')
      },
      getSummaries(param) {
        const {columns, data} = param;
        const sums = [];
        columns.forEach((column, index) => {
          sums.push(this.total[column.property])
        });

        return sums;
      },
      objectSpanMethod({row, column, rowIndex, columnIndex}) {
        if (columnIndex === 0) {
          if (rowIndex % 2 === 0) {
            return {
              rowspan: 2,
              colspan: 1
            };
          } else {
            return {
              rowspan: 0,
              colspan: 0
            };
          }
        }
        if (columnIndex === this.tableTitle.length-1) {
          if (rowIndex % 2 === 0) {
            return {
              rowspan: 2,
              colspan: 1
            };
          } else {
            return {
              rowspan: 0,
              colspan: 0
            };
          }
        }
      },
      printDiv() {
        this.getData()
        setTimeout(()=>{
          this.$nextTick(() => {
            this.$print(this.$refs.print)
          })
        },2000)

      },
      onSearch() {
        this.searchForm.current = 1
        this.getData()
      },
      changePage(current) {
        this.searchForm.current = current
        this.getData()
      },
      async getData() {
        let res = await Http.getReceiveAmountList(this.exportDataFn())
        if (res.code == 200) {
          let data = res.data
          this.tableTitle = data.tableTitle
          this.tableTitle[1].width='60px'
          this.tableData = data.tableData
          this.collectData = data.collectData
          this.tableData2 = data.stampData
          this.tableNum = Math.ceil(this.tableData2.length / 20)
          this.total = data.total
          this.chargePerson = data.chargePerson
        }
      },
      async userLabel() {
        let res = await Http.userLabel({
          roleId:7
        })
        if (res.code == 200) {
          this.formColumns[2].options = res.data
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
    .ws {
        width: 100%;
    }

    .print-box {
        padding-left: 30px;
        padding-top: 20px;
    }

    .break-page {
        page-break-before: auto;
        page-break-after: always;
    }

    .has-gutter {
        color: #000;
    }
    .print-dom{
        opacity: 0;
        position: absolute;
        left:-10000px;
        bottom:0
    }
    @media print {}

</style>
